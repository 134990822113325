import React, { FC, forwardRef, ReactNode } from 'react';

import { omit } from 'lodash';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import ReactRouterLink, { ReactRouterLinkProps } from './reactRouterLink';

interface PropsWithRouter {
  bothSameAndNew?: boolean;
  to: ReactRouterLinkProps['to'];
  withReactRouter: true;
}

interface PropsWithoutRouter {
  bothSameAndNew?: false;
  to: string;
  withReactRouter: false;
}

interface InsideProps {
  children: ReactNode;
  iconSize?: 'large' | 'small' | 'smaller';
  size?: 'small' | 'smaller';
}

type Props = Omit<ReactRouterLinkProps, 'href' | 'target'> & (PropsWithRouter | PropsWithoutRouter) & InsideProps;

const LinkInNewWindow = forwardRef<any, Props>((props, _) => {
  if (props.withReactRouter) {
    if (props.bothSameAndNew) {
      return (
        <Grid alignItems="flex-start" container={ true } flexWrap="nowrap" fontSize={ getFontSize(props) } gap={ 0.5 } justifyContent="space-between">
          <ReactRouterLink { ...omit(props, 'bothSameAndNew', 'withReactRouter', 'iconSize', 'size') }>
            { props.children }
          </ReactRouterLink>
          <ReactRouterLink { ...omit(props, 'bothSameAndNew', 'withReactRouter', 'iconSize', 'size') } height="20px" target="_blank">
            <Box height="16px" margin="2px">
              <OpenInNewIcon sx={ { fontSize: '16px' } } />
            </Box>
          </ReactRouterLink>
        </Grid>
      );
    }

    return (
      <ReactRouterLink { ...omit(props, 'bothSameAndNew', 'withReactRouter', 'iconSize', 'size') } target="_blank">
        <InsideForSame { ...omit(props, 'bothSameAndNew', 'withReactRouter') }>
          { props.children }
        </InsideForSame>
      </ReactRouterLink>
    );
  } else {
    return (
      <Link href={ props.to } target="_blank">
        <InsideForSame { ...omit(props, 'bothSameAndNew', 'withReactRouter') }>
          { props.children }
        </InsideForSame>
      </Link>
    );
  }
});

const getFontSize = (props: InsideProps) => {
  if (props.size === 'smaller') {
    return 'small';
  }

  return undefined;
};

const getIconSize = (props: InsideProps) => {
  if (props.size === 'smaller' || props.iconSize === 'smaller') {
    return '14px';
  }

  if (props.iconSize === 'large') {
    return '28px';
  }

  return '24px';
};

const InsideForSame: FC<InsideProps> = props => (
  <Grid alignItems="flex-start" container={ true } display="inline-flex" flexWrap="nowrap" fontSize={ getFontSize(props) } gap={ 0.5 } width="initial">
    <Grid item={ true }>{ props.children }</Grid>
    <Grid alignItems="center"
          container={ true }
          display="inline-flex"
          height={ getIconSize(props) }
          lineHeight={ getIconSize(props) }
          width="14px"
    >
      <OpenInNewIcon sx={ { fontSize: '14px' } } />
    </Grid>
  </Grid>
);

export default LinkInNewWindow;
