import React, { useCallback, useEffect, useState } from 'react';

import { head } from 'lodash';

import Snackbar from '@mui/material/Snackbar';

import { Configuration } from '../../config';
import { setSignInStatus } from '../../globalState';
import { SignInStatus } from '../../globalState/signInStatusProps';
import useTokenCookie from '../../hooks/useTokenCookie';
import { SecondaryButton } from '../button';

import { useAppForceReloadQuery } from './__generated__/appForceReload';
import { useWatchAppForceReloadSubscription } from './__generated__/watchAppForceReload';

const WatchAppForceReload = () => {
  const { removeTokenFromCookie } = useTokenCookie();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [reason, setReason] = useState<string>();

  const handleReloadWindow = useCallback(() => {
    removeTokenFromCookie();
    setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason });
    window.location.reload();
  }, [reason, removeTokenFromCookie]);

  useAppForceReloadQuery({
    skip: Configuration.build_number === '(unknown)',
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data || data.appForceReloads?.totalCount === 0) {
        return;
      }

      if (head(data.appForceReloads?.nodes)?.newVersion !== Configuration.build_number) {
        setReason('Application updated. (subscription)');
        setOpenSnackbar(true);
      }
    },
  });

  const { data: subscriptionData } = useWatchAppForceReloadSubscription();

  useEffect(() => {
    if (subscriptionData?.listen.relatedNode?.__typename !== 'AppForceReload') {
      return;
    }

    if (subscriptionData.listen.relatedNode?.newVersion !== Configuration.build_number) {
      setReason('Application updated. (init)');
      setOpenSnackbar(true);
    }
  }, [subscriptionData]);

  return (
    <Snackbar action={ <SecondaryButton onClick={ handleReloadWindow }>Reload</SecondaryButton> }
              anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
              message="The application has been updated."
              open={ openSnackbar }
    />
  );
};

export default WatchAppForceReload;
