import React from 'react';

import { isNil } from 'lodash';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CakeIcon from '@mui/icons-material/Cake';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DomainIcon from '@mui/icons-material/Domain';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FlagIcon from '@mui/icons-material/Flag';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GradingIcon from '@mui/icons-material/Grading';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LinkIcon from '@mui/icons-material/Link';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PolicyIcon from '@mui/icons-material/Policy';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import StorageIcon from '@mui/icons-material/Storage';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import WcIcon from '@mui/icons-material/Wc';
import WorkIcon from '@mui/icons-material/Work';
import { SvgIconProps } from '@mui/material';

import { hasRole, Roles } from '../../services/jwtService';

import { MenuListItem } from './menuListItem';

const iconProps = (active?: boolean, childrenCount?: number): SvgIconProps => ({ color: active ? 'primary' : 'action', fontSize: isNil(childrenCount) || childrenCount === 0 ? 'medium' : 'small' });

export const MenuListItems: Array<Array<MenuListItem>> = [
  [
    { title: 'Dashboard', url: '/dashboard', icon: (...props) => <HomeIcon { ...iconProps(...props) } /> },
    { title: 'Profile', url: '/profile', visible: () => false },
    {
      title: 'Employee',
      url: '/employee/list',
      activeUrl: /^\/employee\//,
      icon: (...props) => <PeopleIcon { ...iconProps(...props) } />,
      children: [
        {
          title: 'Employee list',
          browserTitle: 'Employee - List',
          url: '/employee/list',
          icon: (...props) => <PeopleIcon { ...iconProps(...props) } />,
        },
        {
          title: 'Employee detail',
          browserTitle: 'Employee - Detail',
          url: /\/employee\/detail\/(\d+)/,
          icon: (...props) => <PersonIcon { ...iconProps(...props) } />,
          visible: (_token, _userAccount, url) => url.pathname.startsWith('/employee/detail/'),
          disabled: () => true,
        },
        {
          title: 'Add new employee',
          browserTitle: 'Employee - Add',
          url: '/employee/add',
          activeUrl: /^\/employee\/(add|import)/,
          icon: (...props) => <PersonAddIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard),
        },
        {
          title: 'External account',
          browserTitle: 'Employee - External account',
          url: '/employee/externalAccount',
          activeUrl: /^\/employee\/externalAccount/,
          icon: (...props) => <AssignmentIndIcon { ...iconProps(...props) } />,
          visible: () => true,
        },
        {
          title: 'Manager',
          browserTitle: 'Employee - Manager',
          url: '/employee/manager',
          activeUrl: /^\/employee\/manager/,
          icon: (...props) => <WorkIcon { ...iconProps(...props) } />,
          visible: () => true,
        },
      ],
    },
    {
      title: 'Evaluation',
      url: '/evaluation',
      activeUrl: /^\/evaluation/,
      icon: (...props) => <StarIcon { ...iconProps(...props) } />,
      children: [
        {
          title: 'Performance review',
          browserTitle: 'Evaluation - Performance review',
          url: '/evaluation/performance-review/list',
          activeUrl: /^\/evaluation\/performance-review\/(list|detail)/,
          icon: (...props) => <HowToRegIcon { ...iconProps(...props) } />,
        },
        {
          title: 'Probation review',
          browserTitle: 'Evaluation - Probation review',
          url: '/evaluation/probation-review/list',
          activeUrl: /^\/evaluation\/probation-review\//,
          icon: (...props) => <EventAvailableIcon { ...iconProps(...props) } />,
        },
        {
          title: 'Dashboard',
          browserTitle: 'Evaluation - Dashboard',
          url: '/evaluation/performance-review/dashboard',
          activeUrl: /^\/evaluation\/performance-review\/dashboard/,
          icon: (...props) => <FormatListBulletedIcon { ...iconProps(...props) } />,
          visible: (token, userAccount) => hasRole(token, Roles.user_super_admin, Roles.user_admin_private)
            || !!userAccount?.relationEmployeeUserAccount?.employee?.employeeCurrentPosition?.employeeLevel?.canSeePerformanceReviewOverallOfAll
            || !!userAccount?.relationEmployeeUserAccount?.employee?.employeeCurrentPosition?.employeeLevel?.canSeePerformanceReviewOverallOfCountry,
        },
        {
          title: 'Result',
          browserTitle: 'Evaluation - Result',
          url: '/evaluation/performance-review/result',
          activeUrl: /^\/evaluation\/performance-review\/result/,
          icon: (...props) => <GradingIcon { ...iconProps(...props) } />,
          visible: (token, userAccount) => hasRole(token, Roles.user_super_admin, Roles.user_admin_private)
            || !!userAccount?.relationEmployeeUserAccount?.employee?.employeeCurrentPosition?.employeeLevel?.canSeePerformanceReviewOverallOfAll
            || !!userAccount?.relationEmployeeUserAccount?.employee?.employeeCurrentPosition?.employeeLevel?.canSeePerformanceReviewOverallOfCountry,
        },
        {
          title: 'Settings',
          browserTitle: 'Evaluation - Settings',
          url: '/evaluation/settings',
          activeUrl: /^\/evaluation\/settings\//,
          icon: (...props) => <SettingsIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private),
        },
      ],
    },
    {
      title: 'Report',
      url: '/report',
      activeUrl: /^\/report/,
      icon: (...props) => <AssessmentIcon { ...iconProps(...props) } />,
      visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
      children: [
        {
          title: 'Global',
          browserTitle: 'Report - Global',
          url: '/report/global',
          icon: (...props) => <PublicIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Country',
          browserTitle: 'Report - Country',
          url: '/report/country',
          icon: (...props) => <FlagIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Company',
          browserTitle: 'Report - Company',
          url: '/report/company',
          icon: (...props) => <DomainIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Business unit',
          browserTitle: 'Report - Business unit',
          url: '/report/businessUnit',
          icon: (...props) => <WorkIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Job history',
          browserTitle: 'Report - Job history',
          url: '/report/jobHistory',
          icon: (...props) => <TransferWithinAStationIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Working days',
          browserTitle: 'Report - Working days',
          url: '/report/workingDays',
          icon: (...props) => <CalendarTodayIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Age',
          browserTitle: 'Report - Age',
          url: '/report/age',
          icon: (...props) => <CakeIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
        {
          title: 'Gender',
          browserTitle: 'Report - Gender',
          url: '/report/gender',
          icon: (...props) => <WcIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard, Roles.user_advanced),
        },
      ],
    },
    {
      title: 'Configure',
      url: '/configure',
      activeUrl: /^\/configure/,
      icon: (...props) => <SettingsIcon { ...iconProps(...props) } />,
      visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard),
      children: [
        {
          title: 'Master',
          browserTitle: 'Configure - Master',
          url: '/configure/master',
          activeUrl: /^\/configure\/master/,
          icon: (...props) => <StorageIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard),
        },
        {
          title: 'Relation',
          browserTitle: 'Configure - Relation',
          url: '/configure/relation',
          activeUrl: /^\/configure\/relation/,
          icon: (...props) => <LinkIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private),
        },
        {
          title: 'User',
          browserTitle: 'Configure - User',
          url: '/configure/user',
          activeUrl: /^\/configure\/user/,
          icon: (...props) => <AccountCircleIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin, Roles.user_admin_private, Roles.user_admin_standard),
        },
        {
          title: 'Audit logs',
          browserTitle: 'Configure - Audit logs',
          url: '/configure/audit',
          activeUrl: /^\/configure\/audit/,
          icon: (...props) => <PolicyIcon { ...iconProps(...props) } />,
          visible: token => hasRole(token, Roles.user_super_admin),
        },
      ],
    },
  ],
];
