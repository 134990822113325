import React, { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { setPopupMessage, setSignInStatus } from '../../globalState';
import { SignInStatus } from '../../globalState/signInStatusProps';
import useTokenCookie from '../../hooks/useTokenCookie';
import { isEmptySome } from '../../services/objectService';

import { useUserAccountChangeQuery } from './__generated__/userAccountChange';
import { WatchUserForceLogoutDocument, WatchUserForceLogoutSubscription, WatchUserForceLogoutSubscriptionVariables } from './__generated__/watchUserForceLogout';

const WatchUserAccountChange = () => {
  const { token, rawToken, removeTokenFromCookie } = useTokenCookie();
  const apolloClient = useApolloClient();

  const { data } = useUserAccountChangeQuery({
    skip: isEmptySome(true, token),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.currentUserAccount) {
      removeTokenFromCookie();
      setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason: 'Unidentified user.' });
      setPopupMessage({
          severity: 'warning',
          message: 'Unidentified user.',
      });
    } else if (data.currentUserAccount.role !== token?.role) {
      removeTokenFromCookie();
      setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason: 'Your role has been changed.' });
      setPopupMessage({
        severity: 'warning',
        message: 'Your role has been changed.',
      });
    } else if (!data.currentUserAccount.isEnabled) {
      removeTokenFromCookie();
      setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason: 'Your account has been disabled.' });
      setPopupMessage({
        severity: 'warning',
        message: 'Your account has been disabled.',
      });
    }
  }, [token, data, removeTokenFromCookie]);

  useEffect(() => {
    if (isEmptySome(true, rawToken)) {
      return;
    }

    const observer = apolloClient?.subscribe<WatchUserForceLogoutSubscription, WatchUserForceLogoutSubscriptionVariables>({
      query: WatchUserForceLogoutDocument,
    });

    // console.log('[WS] Subscribe topic: user_force_logout_inserted');
    const subscription = observer?.subscribe(({ data: subscriptionData }) => {
      if (subscriptionData?.listen.relatedNode?.__typename !== 'UserForceLogout') {
        return;
      }

      if (subscriptionData.listen.relatedNode.userAccountId === token?.user_account_id) {
        removeTokenFromCookie();
        setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason: `User information has been changed. (${ subscriptionData.listen.relatedNode.reason })` });
        setPopupMessage({
          severity: 'warning',
          message: subscriptionData.listen.relatedNode.reason ?? 'User information has been changed.',
        });
      }
    });

    return () => {
      // console.log('[WS] Unsubscribe topic: user_force_logout_inserted');
      subscription?.unsubscribe();
    };
  }, [token?.user_account_id, apolloClient, rawToken, removeTokenFromCookie]);

  return <></>;
};

export default WatchUserAccountChange;
