import React, { lazy, Suspense, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ErrorBoundary } from '@sentry/react';
import clsx from 'clsx';
import { isEmpty, isNil, trim } from 'lodash';
import { DateTime, Info } from 'luxon';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

import { ApolloProvider } from '@apollo/client';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Header, { drawerWidth } from './components/header';
import LoadingBar from './components/loading';
import Redirect from './components/redirect';
import RenderHtml from './components/renderHtml';
import WatchAppForceReload from './components/watchAppForceReload';
import WatchUserAccountChange from './components/watchUserAccountChange';
import { Configuration } from './config';
import { COOKIE_KEY_JWT_TOKEN_ES } from './const/cookie';
import { getGlobalState, setErrorOccurredDateTime, setLoggedInUser, setPopupMessage, setSignInStatus, useGlobalState } from './globalState';
import { SignInStatus } from './globalState/signInStatusProps';
import { ClearHeaderNavigationStack, HeaderNavigationButtonProvider } from './hooks/useHeaderNavigationButton';
import useQueryString from './hooks/useQueryString';
import { RouterPreviousLocationProvider } from './hooks/useRouterPreviousLocation';
import useTokenCookie, { TokenCookieContext, TokenCookieContextProps } from './hooks/useTokenCookie';
import PageSignIn from './pages/unauthenticated/signIn';
import { SentryErrorFallback } from './sentryErrorFallback';
import { getDateTimeForGoogleForms, getNow, getTimezoneOffset } from './services/dateService';
import { getApolloClient } from './services/graphqlService';
import { isValidJwt, parseJwt, Roles } from './services/jwtService';
import { getRestApi } from './services/restApiService';
import { stringifyLocationDescriptor } from './services/urlService';
import { theme } from './theme';

import './App.scss';

const PageConfigure = lazy(() => import('./pages/configure'));
const PageDashboard = lazy(() => import('./pages/dashboard'));
const PageEmployee = lazy(() => import('./pages/employee'));
const PageEvaluation = lazy(() => import('./pages/evaluation'));
const PageProfile = lazy(() => import('./pages/profile'));
const PageReport = lazy(() => import('./pages/report'));

const AlertWithSnackbar = () => {
  const [popupMessage, setPopupMessage] = useGlobalState('popupMessage');
  const [isOpen, setOpen] = useState(false);

  const handleCloseSnackbar = useCallback((_event: SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setOpen(false);
    setPopupMessage(null);
  }, [setPopupMessage]);

  useEffect(() => {
    if (popupMessage) {
      setOpen(true);
    }
  }, [popupMessage]);

  return (
    <Snackbar autoHideDuration={ popupMessage?.clearTimeout ?? 5000 }
              message={ popupMessage?.message }
              open={ isOpen && !!popupMessage }
              TransitionProps={ { onExited: handleExited } }
              onClose={ handleCloseSnackbar }
    >
      <Alert elevation={ 10 } severity={ popupMessage?.severity } sx={ { display: !popupMessage ? 'none' : 'inherit' } } variant="filled" onClose={ () => setOpen(false) }>
        <RenderHtml value={ popupMessage?.message ?? '' } />
      </Alert>
    </Snackbar>
  );
};

const qsNextKey = 'next';
const qsDefaultNextValue = '/dashboard';

const handleSignOut = (removeTokenFromCookie: () => void, reason: string) => {
  removeTokenFromCookie();
  setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason });
  setPopupMessage({
    severity: 'warning',
    message: reason,
  });

  return;
};

const RoutesSignedIn = () => {
  const location = useLocation();
  const { getSearchParamSingle } = useQueryString<typeof qsNextKey>();
  const qsNext = useMemo(() => getSearchParamSingle(qsNextKey), [getSearchParamSingle]);

  const { token, rawToken, removeTokenFromCookie } = useTokenCookie();

  const restApi = useMemo(() => getRestApi(rawToken), [rawToken]);

  useEffect(() => {
    if (window.location.origin.startsWith(Configuration.frontendUrlBase)) {
      restApi.userAccountAccessHistory({
        timezone_offset: getTimezoneOffset(),
        user_agent: window.navigator.userAgent,
        languages: window.navigator.languages.concat(),
        referrer: window.document.referrer,
      }).then(() => void 0).catch(() => void 0);
    }
  }, [restApi]);

  useEffect(() => {
    if (!token) {
      handleSignOut(removeTokenFromCookie, 'Invalid session.');

      return;
    }

    isValidJwt(rawToken)
      .catch((err) => {
        console.error(err);
        handleSignOut(removeTokenFromCookie, 'Invalid token (1).');
      })
      .then(result => {
        if (!result) {
          handleSignOut(removeTokenFromCookie, 'Invalid token (2).');

          return;
        }

        const expire = DateTime.fromSeconds(token.exp);

        if (expire < getNow()) {
          handleSignOut(removeTokenFromCookie, 'Session has been expired.');

          return;
        }

        // Check roles
        if (!Object.values(Roles).includes(token.role)) {
          handleSignOut(removeTokenFromCookie, 'Invalid role.');

          return;
        }

        console.log('[GTM] Set user_properties');
        TagManager.dataLayer({
          dataLayer: {
            set: 'user_properties',
            user_account_id: token.user_account_id,
          },
        });
      });
  }, [rawToken, token, location.pathname, removeTokenFromCookie]);

  return (
    <Box className="wrapper">
      <WatchAppForceReload />
      <Routes>
        { /* Profile */ }
        <Route element={
          (
            <ClearHeaderNavigationStack>
              <Suspense fallback={ <LoadingBar /> }>
                <PageProfile />
              </Suspense>
            </ClearHeaderNavigationStack>
          )
        }
               path="profile"
        />

        { /* Dashboard */ }
        <Route element={
          (
            <ClearHeaderNavigationStack>
              <PageDashboard />
            </ClearHeaderNavigationStack>
          )
        }
               path="dashboard"
        />

        { /* Employee */ }
        <Route element={ <PageEmployee /> } path="employee/*" />

        { /* Report */ }
        <Route element={
          (
            <ClearHeaderNavigationStack>
              <PageReport />
            </ClearHeaderNavigationStack>
          )
        }
               path="report/*"
        />

        { /* Evaluation */ }
        <Route element={ <PageEvaluation /> } path="evaluation/*" />

        { /* Configure */ }
        <Route element={ <PageConfigure /> } path="configure/*" />

        { /* Others */ }
        <Route element={
          !isNil(qsNext)
            ? <Redirect to={ decodeURIComponent(qsNext) } />
            : <Redirect to="/dashboard" />
        }
               path="*"
        />
      </Routes>
    </Box>
  );
};

const RoutesSignedOut = () => {
  const location = useLocation();
  const { getSearchParamSingle, setSearchParam } = useQueryString<typeof qsNextKey>();
  const qsNext = useMemo(() => getSearchParamSingle(qsNextKey), [getSearchParamSingle]);

  useEffect(() => {
    console.log('[GTM] Clear user_properties');
    TagManager.dataLayer({
      dataLayer: {
        set: 'user_properties',
        user_account_id: null,
      },
    });
  }, []);

  useEffect(() => {
    if (window['gapi']) {
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance) {
        authInstance.signOut();
      }
    }
  }, []);

  useEffect(() => {
    if (location.search && qsNext === '/') {
      setSearchParam({ [qsNextKey]: qsDefaultNextValue });
    }
  }, [location, qsNext, setSearchParam]);

  return (
    <Box className="wrapper">
      <Box className="colorHeader">
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Box className="contents">
        <Routes>
          <Route element={ <PageSignIn /> } path="signIn" />
          <Route element={
                   (
                     <Redirect to={
                                 {
                                   pathname: '/signIn',
                                   search: `?${ qsNextKey }=${ encodeURIComponent(location.pathname !== '/' ? `${ location.pathname }${ location.search }` : qsDefaultNextValue) }`,
                                 }
                               }
                     />
                   )
                 }
                 path="*"
          />
        </Routes>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }),
);

const AppWithinTheme = () => {
  const location = useLocation();
  const classes = useStyles();
  const { token, rawToken } = useTokenCookie();

  const [signInStatus] = useGlobalState('signInStatus');
  const loggedInUser = getGlobalState('loggedInUser');

  const isInvalidDomainName = useMemo(
    () => ['http://localhost:', 'http://127.0.0.1:'].every(v => !window.location.origin.startsWith(v))
      && window.location.origin !== Configuration.frontendUrlBase,
    []
  );

  if (isInvalidDomainName) {
    // noinspection HtmlUnknownTarget
    return (
      <Box className="App">
        <Box margin="auto" textAlign="center">
          <h1>Important notice</h1>
          <p>
            The URL has been changed to&nbsp;
            <a href={ `${ Configuration.frontendUrlBase }${ location.pathname }${ location.search }` }>{ Configuration.frontendUrlBase }</a>
            .
          </p>
          <img alt="" src="/moving.png" style={ { width: '318px', height: '400px' } } />
          <Box color={ theme.palette.text.secondary }>
            Build number:&nbsp;
            { Configuration.build_number }
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <ErrorBoundary dialogOptions={ { user: { email: loggedInUser.emailAddress, name: loggedInUser.userName } } }
                   fallback={ errorData => <SentryErrorFallback { ...errorData } /> }
                   showDialog={ Configuration.isProduction }
    >
      <ApolloProvider client={ getApolloClient(() => token, () => rawToken, () => setErrorOccurredDateTime(getDateTimeForGoogleForms())) }>
        <LocalizationProvider dateAdapter={ CustomAdapterLuxon }>
          <WatchUserAccountChange />
          <Box className="App">
            {
              signInStatus.status === SignInStatus.SIGNED_IN && isValidJwt(rawToken)
                ? (
                  <HeaderNavigationButtonProvider>
                    <RouterPreviousLocationProvider>
                      <Box className="header">
                        <Header />
                      </Box>
                      <Box className={ `contents ${ signInStatus.status } ` + clsx(classes.content, classes.contentShift) }>
                        <Suspense fallback={ <Fallback /> }>
                          <RoutesSignedIn />
                        </Suspense>
                      </Box>
                    </RouterPreviousLocationProvider>
                  </HeaderNavigationButtonProvider>
                )
                : (
                  <Box className={ `contents ${ signInStatus.status } ` + clsx(classes.content, classes.contentShift) }>
                    <Suspense fallback={ <Fallback /> }>
                      <ClearHeaderNavigationStack>
                        <RoutesSignedOut />
                      </ClearHeaderNavigationStack>
                    </Suspense>
                  </Box>
                )
            }
            <AlertWithSnackbar />
          </Box>
        </LocalizationProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export const App = () => {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY_JWT_TOKEN_ES]);

  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    if (pathname !== location.pathname) {
      setPathname(location.pathname);
      console.log(`[Router] ${ Configuration.frontendUrlBase }${ stringifyLocationDescriptor(location) }`);
    }
  }, [pathname, location]);

  useEffect(() => {
    document.getElementsByTagName('body')[0].setAttribute('data-location', pathname);
    setErrorOccurredDateTime(null);
  }, [pathname]);

  const getRawToken = useCallback(() => cookies[COOKIE_KEY_JWT_TOKEN_ES], [cookies]);
  const getToken = useCallback(() => parseJwt(getRawToken()), [getRawToken]);

  useEffect(() => {
    const status = isEmpty(trim(cookies[COOKIE_KEY_JWT_TOKEN_ES])) ? SignInStatus.SIGNED_OUT : SignInStatus.SIGNED_IN;

    setSignInStatus({ status });

    if (status === SignInStatus.SIGNED_OUT) {
      setLoggedInUser({ userName: undefined, emailAddress: undefined });
    }
  }, [cookies]);

  const tokenCookieContextValue: TokenCookieContextProps = useMemo(() => ({
    token: getToken(),
    rawToken: getRawToken(),
    setTokenToCookie: rawToken => {
      const jwtBody = parseJwt(rawToken);

      setCookie(
        COOKIE_KEY_JWT_TOKEN_ES,
        rawToken,
        {
          path: '/',
          expires: DateTime.max(DateTime.fromJSDate(new Date(jwtBody.exp * 1000)), DateTime.now().plus({ day: 6 })).toJSDate(),
          secure: Configuration.environmentName !== 'local',
        }
      );
    },
    removeTokenFromCookie: () => removeCookie(COOKIE_KEY_JWT_TOKEN_ES),
  }), [getToken, getRawToken, setCookie, removeCookie]);

  return (
    <TokenCookieContext.Provider value={ tokenCookieContextValue }>
      <AppWithinTheme />
    </TokenCookieContext.Provider>
  );
};

const Fallback = () => (
  <Grid alignItems="center" container={ true } height="100%" justifyContent="center" width="100%">
    <Grid item={ true }>
      <CircularProgress size={ 64 } />
    </Grid>
  </Grid>
);

class CustomAdapterLuxon extends AdapterLuxon {
  override getWeekdays = () => {
    return Info.weekdaysFormat('short', { locale: this.locale });
  };
}
