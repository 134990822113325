import { createGlobalState } from 'react-hooks-global-state';

import { Configuration } from '../config';

import { LoggedInUserProps } from './loggedInUserProps';
import { PopupMessageProps } from './popupMessageProps';
import { SignInStatus, signInStatusOnChange, SignInStatusProps } from './signInStatusProps';

export interface GlobalState extends PopupMessageProps, SignInStatusProps, LoggedInUserProps {
  errorOccurredDateTime: string | null;
  browserTitle: string;
  openHelp: boolean;
}

export const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState<GlobalState>({
  popupMessage: null,
  signInStatus: { status: SignInStatus.SIGNED_OUT },
  errorOccurredDateTime: null,
  browserTitle: Configuration.title,
  openHelp: false,
  loggedInUser: { userName: undefined, emailAddress: undefined },
});

export const setBrowserTitle = (update: string): void => {
  setGlobalState('browserTitle', update);
  document.title = update;
};

export const setErrorOccurredDateTime = (update: string | null): void => setGlobalState('errorOccurredDateTime', update);

export const setPopupMessage = (update: PopupMessageProps['popupMessage']): void => setGlobalState('popupMessage', update);

export const setSignInStatus = (update: SignInStatusProps['signInStatus']): void => {
  setGlobalState('signInStatus', update);
  signInStatusOnChange(update);
};

export const setLoggedInUser = (update: LoggedInUserProps['loggedInUser']): void => setGlobalState('loggedInUser', update);
