import React, { ForwardedRef, forwardRef, ReactNode, RefObject, SyntheticEvent, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { isArray, isEmpty, isFunction, isNil, merge, toString, trim } from 'lodash';

import { SelectProps, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { isNotEmptySome } from '../../services/objectService';
import { plural } from '../../services/stringService';
import ShowIf from '../showIf';
import ShowIfNotEmpty from '../showIf/notEmpty';

import SelectorMenuItem, { SelectorMenuItemValue } from './menuItem';

interface PropsCommon<TValue extends SelectorMenuItemValue> {
  appendMenuItems?: Array<SelectorMenuItem<TValue>>;
  className?: string;
  defaultTo?: boolean;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: ReactNode | ((value: TValue | Array<TValue> | undefined) => ReactNode);
  inputRef?: RefObject<HTMLInputElement>;
  label?: string;
  menuItems: Array<SelectorMenuItem<TValue>>;
  minWidth?: string;
  multiple?: true | boolean;
  open?: boolean;
  prependMenuItems?: Array<SelectorMenuItem<TValue>>;
  renderValue?: (value: TValue) => ReactNode;
  required?: boolean;
  sx?: SxProps;
  variant?: SelectProps['variant'];
  onClose?: (event: SyntheticEvent) => void;
  onOpen?: (event: SyntheticEvent) => void;
}

export interface PropsSingle<TValue extends SelectorMenuItemValue> extends PropsCommon<TValue> {
  multiple?: false;
  multipleRenderAtMostSelectedValues?: never;
  value: TValue | undefined;
  onChange: (value: TValue) => void;
}

interface PropsMultiple<TValue extends SelectorMenuItemValue> extends PropsCommon<TValue> {
  multiple: true;
  multipleRenderAtMostSelectedValues?: number;
  value: Array<TValue> | undefined;
  onChange: (value: Array<TValue>) => void;
}

type Props<TValue extends SelectorMenuItemValue> = PropsSingle<TValue> | PropsMultiple<TValue>;

export interface SelectorHandlers {
  open: () => void;
  close: () => void;
}

const getMenuItems = <TValue extends SelectorMenuItemValue>(multiple: boolean, menuItems: Array<SelectorMenuItem<TValue>> | undefined, selectedValues: Array<TValue | undefined> | undefined) =>
  (menuItems ?? []).map(item => {
    const name = isFunction(item.name) ? item.name(item.value) : item.name;

    return (
      <MenuItem key={ toString(item.value) } disabled={ item.disabled } value={ toString(item.value) ?? undefined }>
        {
          multiple
            ? (
              <>
                <Checkbox checked={ selectedValues?.includes(item.value) } disableRipple={ true } size="small" sx={ { padding: '4px' } } />
                <ListItemText disableTypography={ true } primary={ name } />
              </>
            )
            : name
        }
      </MenuItem>
    );
  });

const Selector = forwardRef(<TValue extends SelectorMenuItemValue>(props: Props<TValue>, ref: ForwardedRef<SelectorHandlers | undefined>) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle<any, SelectorHandlers>(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  const allMenuItems = useMemo(
    () => [...props.prependMenuItems ?? [], ...props.menuItems, ...props.appendMenuItems ?? []],
    [props.prependMenuItems, props.menuItems, props.appendMenuItems]
  );

  const renderValue = useCallback((selected: Array<TValue> | TValue): ReactNode => {
    if (!isArray(selected)) {
      const option = allMenuItems.find(n => n?.value === selected);

      if (!option) {
        return selected;
      } else if (isFunction(props.renderValue)) {
        return props.renderValue(option.value);
      } else if (isFunction(option.name)) {
        return option.name(option.value);
      } else {
        return option.name;
      }
    }

    const multipleRenderAtMostSelectedValues = Math.max(-1, props.multipleRenderAtMostSelectedValues ?? 3);

    return (
      <Box display="flex" flexWrap="wrap">
        {
          multipleRenderAtMostSelectedValues > 0 && selected.length > multipleRenderAtMostSelectedValues
            ? selected.length === props.menuItems.length
              ? '(All options)'
              : `(${ plural(selected.length, 'option') })`
            : selected
              .map(value => allMenuItems.find(n => n?.value === value))
              .filter(it => !isNil(it))
              .map(it => it as SelectorMenuItem<TValue>)
              .map((it, index) => {
                let label: ReactNode;

                if (isFunction(props.renderValue)) {
                  label = props.renderValue(it.value);
                } else if (isFunction(it.name)) {
                  label = it.name(it.value);
                } else {
                  label = it.name;
                }

                return <Chip key={ index } label={ label } variant="outlined" />;
              })
        }
      </Box>
    );
  }, [allMenuItems, props]);

  const isLabelEmpty = useMemo(() => isEmpty(trim(props.label)), [props.label]);
  const isShrink = useMemo(
    () => {
      if (props.multiple) {
        return !isEmpty(props.value);
      }

      return !isEmpty(renderValue) || allMenuItems.findIndex(it => it.value === props.value && isNotEmptySome(true, it.value, it.name)) >= 0;
    },
    [allMenuItems, props.multiple, props.value, renderValue]
  );

  return (
    <FormControl className={ props.className }
                 disabled={ props.disabled }
                 error={ props.error }
                 fullWidth={ props.fullWidth }
                 required={ props.required }
                 size="small"
                 sx={ merge(props.sx, { minWidth: props.minWidth }) }
                 variant={ props.variant }
    >
      <ShowIf test={ !isLabelEmpty }>
        <InputLabel id="label" shrink={ isShrink }>{ props.label }</InputLabel>
      </ShowIf>
      <Select displayEmpty={ true }
              inputRef={ props.inputRef }
              label={ isLabelEmpty ? undefined : props.label + (props.required ? ' *' : '') }
              labelId="label"
              multiple={ props.multiple }
              notched={ isShrink }
              open={ props.open ?? open }
              renderValue={ renderValue }
              value={ props.value }
              variant={ props.variant }
              onChange={ event => !props.multiple ? props.onChange(event.target.value as TValue) : props.onChange(event.target.value as Array<TValue>) }
              onClose={ props.onClose ?? (() => setOpen(false)) }
              onOpen={ props.onOpen ?? (() => setOpen(true)) }
      >
        { getMenuItems(props.multiple === true, allMenuItems, isArray(props.value) ? props.value : [props.value]) }
      </Select>
      <ShowIfNotEmpty value={ props.helperText }>
        {
          helperText => (
            <FormHelperText component="div" error={ props.error }>
              { isFunction(helperText) ? helperText(props.value) : helperText }
            </FormHelperText>
          )
        }
      </ShowIfNotEmpty>
    </FormControl>
  );
});

export default Selector;
