import React, { ReactElement, ReactNode } from 'react';

import humanizeDuration from 'humanize-duration';
import { isNil, isString } from 'lodash';
import { DateTime, Duration, DurationLike, Interval } from 'luxon';

import { PerformanceReviewQuarter } from '../graphql/schemas';

import { coalesceNotEmpty, Nullable, NullOrUndefinedOr } from './objectService';

export const DATE_FORMAT_ORIGINAL = 'yyyy-LL-dd';

export const DATE_FORMAT_DISPLAY = 'LLL dd, yyyy';

export const DATE_FORMAT_DISPLAY_WITH_WEEKDAY = 'LLL dd, yyyy (ccc)';

export const DATE_FORMAT_DISPLAY_WITH_WEEKDAY_WITHOUT_YEAR = 'LLL dd (ccc)';

export const DATETIME_FORMAT_DISPLAY = 'LLL dd, yyyy HH:mm';

export const DATETIME_FORMAT_DISPLAY_SECONDS = 'LLL dd, yyyy HH:mm:ss';

export const DATETIME_FORMAT_ORIGINAL = 'yyyy-LL-dd HH:mm';

export const DATETIME_FORMAT_GOOGLE_FORMS = 'yyyy-LL-dd+HH:mm';

export const TIME_FORMAT_DISPLAY = 'HH:mm';

export const TIME_FORMAT_DISPLAY_SECONDS = 'HH:mm:ss';

export const getNow = () => DateTime.now();

export const parseDate = (date: NullOrUndefinedOr<string>, originalFormat: string = DATE_FORMAT_ORIGINAL) => isNil(date)
  ? DateTime.invalid('isNil')
  : DateTime.fromFormat(date, originalFormat || DATE_FORMAT_ORIGINAL);

export const parseDateTime = (date: NullOrUndefinedOr<string>) => isNil(date)
  ? DateTime.invalid('isNil')
  : DateTime.fromISO(date);

export const formatDate = (date: DateTime, displayFormat: string = DATE_FORMAT_DISPLAY, altWhenInvalidDate = '') => {
  if (!date.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return date.toFormat(coalesceNotEmpty(true, displayFormat, DATE_FORMAT_DISPLAY));
};

export const formatDateToISO8601 = (date: DateTime, altWhenInvalidDate = '') => {
  if (!date.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return date.toFormat(DATE_FORMAT_ORIGINAL);
};

export const formatDateTime = (date: DateTime, displayFormat: string = DATETIME_FORMAT_DISPLAY, altWhenInvalidDate = '') => {
  if (!date.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return date.toFormat(displayFormat || DATETIME_FORMAT_DISPLAY);
};

export const convertDateFormat = (date: NullOrUndefinedOr<string>, displayFormat = DATE_FORMAT_DISPLAY, altWhenInvalidDate = '') => {
  const dateTime = parseDate(date);

  if (!dateTime.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return formatDate(dateTime, coalesceNotEmpty(true, displayFormat, DATE_FORMAT_DISPLAY));
};

export const changeDateOriginal = (date: string, func: (date: DateTime) => DateTime): string => formatDateToISO8601(func(parseDate(date)));

export const convertDateTimeFormat = (date: NullOrUndefinedOr<string>, displayFormat = DATETIME_FORMAT_DISPLAY_SECONDS, altWhenInvalidDate: ReactNode = ''): string => {
  const m = parseDateTime(date);

  if (!m.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return formatDate(m, coalesceNotEmpty(true, displayFormat, DATETIME_FORMAT_DISPLAY_SECONDS));
};

export const convertDateTimeFormatWithLineBreak = (
  date: NullOrUndefinedOr<string>,
  displayDateFormat = DATE_FORMAT_DISPLAY,
  displayTimeFormat = TIME_FORMAT_DISPLAY_SECONDS,
  altWhenInvalidDate = ''
): string | ReactElement => {
  const m = parseDateTime(date);

  if (!m.isValid) {
    return coalesceNotEmpty(true, altWhenInvalidDate, '');
  }

  return (
    <>
      { m.toFormat(coalesceNotEmpty(true, displayDateFormat, DATE_FORMAT_DISPLAY)) }
      <br />
      { m.toFormat(coalesceNotEmpty(true, displayTimeFormat, TIME_FORMAT_DISPLAY_SECONDS)) }
    </>
  );
};

export const getDatesBetween = (dateFrom: NullOrUndefinedOr<string | DateTime>, dateTo?: Nullable<string | DateTime>) => {
  const _dateFrom = isNil(dateFrom)
    ? DateTime.now()
    : isString(dateFrom)
      ? parseDate(dateFrom)
      : dateFrom;
  const _dateTo = isNil(dateTo)
    ? DateTime.now()
    : isString(dateTo)
      ? parseDate(dateTo)
      : dateTo;

  if (!_dateFrom.isValid || !_dateTo.isValid) {
    return Duration.fromMillis(0);
  }

  return _dateTo.diff(_dateFrom, 'days');
};

export const getDaysBetween = (dateFrom: NullOrUndefinedOr<string | DateTime>, dateTo?: NullOrUndefinedOr<string | DateTime>) => getDatesBetween(dateFrom, dateTo).as('days');

export const getTimezoneOffset = () => new Date().getTimezoneOffset();

export const getDateTimeForGoogleForms = () => DateTime.now().toFormat(DATETIME_FORMAT_GOOGLE_FORMS);

export const getLastWeekday = (baseDate: DateTime): DateTime => {
  switch (baseDate.weekday) {
    case 7:
      // baseDate is Sunday
      return baseDate.minus({ day: 2 });
    case 6:
      // baseDate is Saturday
      return baseDate.minus({ day: 1 });
  }

  return baseDate;
};

export const getStartDate = (quarter: PerformanceReviewQuarter): string => {
  switch (quarter) {
    case PerformanceReviewQuarter.Q1_Q2:
      return '01-01';
    case PerformanceReviewQuarter.Q3_Q4:
      return '07-01';
  }
};

export const getEndDate = (quarter: PerformanceReviewQuarter): string => {
  switch (quarter) {
    case PerformanceReviewQuarter.Q1_Q2:
      return '06-30';
    case PerformanceReviewQuarter.Q3_Q4:
      return '12-31';
  }
};

export const getQuarter = (day: DateTime): PerformanceReviewQuarter => {
  if (day.month >= 1 && day.month <= 6) {
    // Jan to June
    return PerformanceReviewQuarter.Q1_Q2;
  } else {
    // July to December
    return PerformanceReviewQuarter.Q3_Q4;
  }
};

export const formatQuarter = (quarter: NullOrUndefinedOr<PerformanceReviewQuarter>) => {
  switch (quarter) {
    case PerformanceReviewQuarter.Q1_Q2:
      return 'Q1/Q2';
    case PerformanceReviewQuarter.Q3_Q4:
      return 'Q3/Q4';
    default:
      return '';
  }
};

export const formatYearQuarter = (object: NullOrUndefinedOr<{ year: number; quarter: PerformanceReviewQuarter }>) => {
  if (!object) {
    return '';
  }

  return `${ object.year } ${ formatQuarter(object.quarter) }`;
};

export const getDateRange = (startDate: DateTime, endDate: DateTime, period: DurationLike) => {
  const days = function*(interval: Interval) {
    let cursor = interval.start.startOf('day');

    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus(period);
    }
  };

  const [minDate, maxDate] = [DateTime.min(startDate, endDate), DateTime.max(startDate, endDate)];
  const interval = minDate.until(maxDate);

  if (!interval.isValid) {
    return [];
  }

  return Array.from(days(interval));
};

export type HumanizeDurationUnit = humanizeDuration.Unit;

interface HumanizerOptions {
  in?: boolean;
  minus?: 'ago' | 'passed';
  today?: boolean;
  tomorrow?: boolean;
  dayAfterTomorrow?: boolean;
  yesterday?: boolean;
  humanizerOptions?: Omit<humanizeDuration.HumanizerOptions, 'units'>
}

export const humanize = (duration: Duration, units: Array<HumanizeDurationUnit>, options?: HumanizerOptions): string => {
  if (!duration.isValid) {
    return '';
  }

  const totalDays = duration.as('day');

  if (options?.yesterday && totalDays >= -1 && totalDays < 0) {
    return 'Yesterday';
  } else if (options?.today && totalDays >= 0 && totalDays < 1) {
    return 'Today';
  } else if (options?.tomorrow && totalDays >= 1 && totalDays < 2) {
    return 'Tomorrow';
  } else if (options?.tomorrow && totalDays >= 2 && totalDays < 3) {
    return 'Day after tomorrow';
  }

  const totalMilliseconds = duration.as('millisecond');
  const humanizer = humanizeDuration.humanizer({ languages: options?.humanizerOptions?.languages ?? {} });
  const str = humanizer(totalMilliseconds, { language: 'en', round: true, delimiter: ' ', ...options?.humanizerOptions, units });

  if (totalMilliseconds < 0 && options?.minus) {
    return `${ str } ${ options.minus }`;
  } else if (totalMilliseconds >= 0 && options?.in) {
    return `in ${ str }`;
  }

  return str;
};

export const dateTimeValidOr = <T extends any = any>(dateTime: DateTime, whenInvalid: T): DateTime | T => dateTime.isValid ? dateTime : whenInvalid;
