import * as jose from 'jose';
import { isEmpty, isString } from 'lodash';

import { Configuration } from '../config';

export enum Roles {
  user_super_admin = 'user_super_admin',
  user_admin_private = 'user_admin_private',
  user_admin_standard = 'user_admin_standard',
  user_advanced = 'user_advanced',
  user_basic = 'user_basic',
}

export interface JwtBody {
  aud: Array<string>;
  iss: string;
  exp: number;
  iat: number;
  nbf: number;
  role: Roles;
  user_account_id: string;
  employee_id?: string;
  display_name: string;
  pid?: string;
  google_id?: string;
}

const decodeJwt = (jwt: string | undefined): Record<string, any> => {
  try {
    if (!jwt) {
      return {};
    }

    const jwtBody = jwt.split('.')[1];

    if (!jwtBody) {
      return {};
    }

    return JSON.parse(window.atob(jwtBody));
  } catch (e) {
    return {};
  }
};

export const parseJwt = (token: string): JwtBody => {
  return decodeJwt(token) as JwtBody;
};

export const hasRole = (token: JwtBody | undefined, ...roles: Array<Roles>) => !token ? false : roles.includes(token.role);

export const isValidJwt = async (jwt: string | null | undefined) => {
  if (isEmpty(jwt) || !isString(jwt)) {
    return false;
  }

  try {
    const key = await jose.importSPKI(Configuration.jwtPublicKey.trim(), 'ES384');

    await jose.jwtVerify(jwt, key, { issuer: Configuration.backendUrlBase });

    return true;
  } catch (e) {
    return false;
  }
};
