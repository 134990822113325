import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isArray, isBoolean, isEmpty, isEqual, isNil, toString as lodashToString, trim as lodashTrim, sortBy } from 'lodash';

import { isEmptyAll, NullOrUndefinedOr } from '../services/objectService';

type QueryStringSetValue = string | number | boolean | null;

const toString = (value: NullOrUndefinedOr<QueryStringSetValue>): string => {
  if (isBoolean(value)) {
    return value ? 'true' : 'false';
  }

  return lodashToString(value);
};

const useQueryString = <TKeySingle extends string, TKeyMultiple extends string = never>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const isSearchParamSetSingle = useCallback((name: TKeySingle, trim: boolean = true) => {
    if (!searchParams.has(name)) {
      return false;
    }

    const value = searchParams.get(name);

    return !isNil(value) && !isEmpty(trim ? lodashTrim(value) : value);
  }, [searchParams]);

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const isSearchParamSetMultiple = useCallback((name: TKeyMultiple, trimAll: boolean = true) => {
    if (!searchParams.has(name)) {
      return false;
    }

    return !isEmptyAll(trimAll, ...searchParams.getAll(name));
  }, [searchParams]);

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const getSearchParamSingle = useCallback(<V extends string>(name: TKeySingle, trim: boolean = true): null | V => {
    if (!isSearchParamSetSingle(name)) {
      return null;
    }

    const value = searchParams.get(name) as V;

    return trim ? lodashTrim(value) as V : value;
  }, [isSearchParamSetSingle, searchParams]);

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const getSearchParamMultiple = useCallback(<V extends string>(name: TKeyMultiple, trimAll: boolean = true): Array<V> => {
    if (!isSearchParamSetMultiple(name)) {
      return [];
    }

    const values = searchParams.getAll(name);

    return trimAll ? values.map(it => lodashTrim(it) as V) : values as Array<V>;
  }, [isSearchParamSetMultiple, searchParams]);

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const setSearchParam = useCallback((newValues: Partial<Record<TKeySingle, QueryStringSetValue> | Record<TKeyMultiple, Array<QueryStringSetValue>>>, push: boolean = false) => {
    setSearchParams(prev => {
      for (const name of Object.keys(newValues)) {
        const value = newValues[name as TKeySingle & TKeyMultiple];

        if (prev.has(name)) {
          if (isArray(value)) {
            if (isEqual(sortBy(prev.getAll(name)), sortBy(value.map(toString)))) {
              continue;
            }
          } else if (isEqual(prev.get(name), toString(value))) {
            continue;
          }
        }

        if (isNil(value) || (isArray(value) && isEmptyAll(true, value))) {
          console.log(`[setSearchParam] delete: ${ name }`);
          prev.delete(name);
        } else {
          if (isArray(value)) {
            const values = sortBy(value.map(toString));

            console.log(`[setSearchParam] set (multiple): ${ name } = ${ values }`);

            prev.delete(name);

            for (const v of values) {
              prev.append(name, v);
            }
          } else {
            console.log(`[setSearchParam] set (single): ${ name } = ${ value }`);
            prev.set(name, toString(value));
          }
        }
      }

      return prev;
    }, { replace: !push });
  }, [setSearchParams]);

  return { getSearchParamSingle, getSearchParamMultiple, setSearchParam };
};

export default useQueryString;
