import React, { useEffect } from 'react';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';

import { App } from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';

Sentry.init({
  dsn: 'https://6b1bfa2bc41f42718c7c4f9c18b870d7@o160373.ingest.sentry.io/6693038',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
  ],
  tracesSampleRate: 0.2,
  ignoreErrors: [/^Socket closed with event/, 'Failed to fetch', 'Illegal invocation'],
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// noinspection SpellCheckingInspection
TagManager.initialize({ gtmId: 'GTM-MRBHJD6' });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    <SentryRoutes>
      <Route element={
        (
          <CookiesProvider>
            <StylesProvider injectFirst={ true }>
              <StyledEngineProvider injectFirst={ true }>
                <ThemeProvider theme={ theme }>
                  <CssBaseline />
                  <App />
                </ThemeProvider>
              </StyledEngineProvider>
            </StylesProvider>
          </CookiesProvider>
        )
      }
             path="*"
      />
    </SentryRoutes>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
