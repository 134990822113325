import React, { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Location } from '@remix-run/router';
import clsx from 'clsx';
import { flattenDeep, isEmpty, ListOfRecursiveArraysOrValues } from 'lodash';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { IconProps } from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Configuration } from '../../config';
import { setBrowserTitle, setLoggedInUser, setSignInStatus, useGlobalState } from '../../globalState';
import { SignInStatus } from '../../globalState/signInStatusProps';
import useHeaderNavigationButton from '../../hooks/useHeaderNavigationButton';
import useTokenCookie from '../../hooks/useTokenCookie';
import logo_black from '../../images/logo_black.svg';
import { coalesceNotEmpty, Nullable } from '../../services/objectService';
import { getRestApi } from '../../services/restApiService';
import ShowIf from '../showIf';
import ShowIfNotEmpty from '../showIf/notEmpty';

import { useHeader_EmployeeQuery, useHeader_UserAccountQuery } from './__generated__';
import { HelpDialog } from './help';
import css from './index.module.scss';
import { MenuListItems } from './menuList';
import { MenuListItem, MenuListItemsComponent } from './menuListItem';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 1200,
      border: 0,
    },
    appBarShift: {
      width: `calc(100% - ${ drawerWidth }px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '6px',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'center',
    },
  })
);

const predicateMenuListItemUrl = (url: string | RegExp | undefined, pathName: string): boolean => {
  if (typeof url === 'undefined') {
    return false;
  } else if (typeof url === 'string') {
    return url === pathName;
  } else {
    return url.test(pathName);
  }
};

const HeaderComponent = () => {
  const classes = useStyles();

  const { token, rawToken, removeTokenFromCookie } = useTokenCookie();
  const location = useLocation();

  const headerNavigationButton = useHeaderNavigationButton();
  const [signInStatus] = useGlobalState('signInStatus');
  const [openHelpState, setOpenHelpState] = useGlobalState('openHelp');

  const [userNameAnchorEl, setUserNameAnchorEl] = useState<Nullable<Element>>(null);
  const [pageTitle, setPageTitle] = useState('');
  const [icon, setIcon] = useState<ReactElement<IconProps>>();
  const [backendBuildNumber, setBackendBuildNumber] = useState('');
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const [leftImageWidth, setLeftImageWidth] = useState<number>(0);
  const refLeftTitle = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (refLeftTitle.current) {
      setLeftImageWidth(refLeftTitle.current.getBoundingClientRect().width);
    }
  }, [refLeftTitle]);

  const { data: userAccount } = useHeader_UserAccountQuery({
    onCompleted: data => {
      setLoggedInUser({ userName: data.currentUserAccount?.displayName, emailAddress: data.currentUserAccount?.emailAddress });
    },
  });
  const { data: employee } = useHeader_EmployeeQuery();

  const onUserMenuClose = useCallback((action?: () => void) => () => {
    action?.();
    setUserNameAnchorEl(null);
  }, []);

  useEffect(() => {
    getRestApi().version()
      .then(json => {
        setBackendBuildNumber(json.data.build_hash.substring(0, 7));
      })
      .catch(() => setBackendBuildNumber('-'));
  }, []);

  useEffect(() => {
    if (openHelpState) {
      setShowHelpDialog(true);
    }
  }, [openHelpState]);

  useEffect(() => {
    if (!showHelpDialog) {
      setOpenHelpState(false);
    }
  }, [showHelpDialog, setOpenHelpState]);

  const onLocationChange = useCallback((location: Location) => {
    if (signInStatus.status !== SignInStatus.SIGNED_IN) {
      document.title = Configuration.title;
    } else {
      const getMenuItem = (root: ListOfRecursiveArraysOrValues<MenuListItem>) =>
        flattenDeep(root).find((v: MenuListItem) => predicateMenuListItemUrl(v.activeUrl ?? v.url, location.pathname));

      let menuItem: MenuListItem | undefined = getMenuItem(MenuListItems);

      while (menuItem) {
        if (menuItem?.children) {
          const childMenuItem = getMenuItem(menuItem.children);

          if (childMenuItem) {
            menuItem = childMenuItem;
            continue;
          }
          break;
        } else {
          break;
        }
      }

      if (!menuItem) {
        setBrowserTitle(Configuration.title);
        setPageTitle(Configuration.title);
        setIcon(undefined);
      } else {
        const browserTitle = coalesceNotEmpty(true, menuItem.browserTitle, menuItem.title);

        setBrowserTitle((browserTitle ? `${ browserTitle } :: ` : '') + Configuration.title);
        setPageTitle(browserTitle ?? Configuration.title);
        setIcon(menuItem.icon?.());
      }
    }
  }, [signInStatus]);

  useEffect(() => {
    onLocationChange(location);
  }, [location, onLocationChange]);

  const signOut = useCallback(() => {
    removeTokenFromCookie();
    setSignInStatus({ status: SignInStatus.SIGNED_OUT, reason: 'Signed out from header.' });
  }, [removeTokenFromCookie]);

  return (
    <Box className={ css.headerWrapper }>
      <AppBar className={ clsx(classes.appBar, classes.appBarShift) } position="fixed">
        <Toolbar>
          <ShowIf test={ !Configuration.isProduction }>
            <Chip color="info" label={ Configuration.environmentName } size="small" sx={ { textTransform: 'uppercase', marginRight: '1em' } } variant="filled" />
          </ShowIf>
          <ShowIfNotEmpty value={ headerNavigationButton.getLatestItem() }>
            {
              item => (
                <Tooltip arrow={ true } placement="bottom-start" title={ `Back to ${ item.backToText }` }>
                  <IconButton size="large" sx={ { marginLeft: '-12px', marginRight: '8px' } } onClick={ headerNavigationButton.back }>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          </ShowIfNotEmpty>
          <ShowIfNotEmpty value={ icon }>
            { icon => <Box height="24px" marginRight={ 2 }>{ icon }</Box> }
          </ShowIfNotEmpty>
          <Typography fontSize="24px" fontWeight="300" letterSpacing="1px" noWrap={ true } variant="h1">
            { signInStatus.status === SignInStatus.SIGNED_IN ? (!isEmpty(pageTitle) ? pageTitle : Configuration.title) : Configuration.title }
          </Typography>
          <Box flex={ 1 } />
          <Tooltip arrow={ true } title="Help">
            <IconButton size="large" onClick={ () => setShowHelpDialog(true) }>
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <ShowIfNotEmpty value={ userAccount?.currentUserAccount?.googleProfilePictureUrl }>
            {
              ({
                ifFalse: () => (
                  <Button variant="text" onClick={ event => setUserNameAnchorEl(event.currentTarget) }>
                    { userAccount?.currentUserAccount?.displayName }
                  </Button>
                ),
                ifTrue: googleProfilePictureUrl => (
                  <IconButton size="large" onClick={ event => setUserNameAnchorEl(event.currentTarget) }>
                    <Avatar alt={ userAccount?.currentUserAccount?.displayName }
                            src={ `${ googleProfilePictureUrl }?authorization=${ rawToken }` }
                            sx={ { width: '24px', height: '24px' } }
                    />
                  </IconButton>
                ),
              })
            }
          </ShowIfNotEmpty>
          <Menu anchorEl={ userNameAnchorEl }
                anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                keepMounted={ true }
                open={ Boolean(userNameAnchorEl) }
                transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                onClose={ onUserMenuClose() }
          >
            <MenuItem disabled={ true }>
              <ListItemText>{ userAccount?.currentUserAccount?.displayName }</ListItemText>
            </MenuItem>
            <MenuItem component={ RouterLink } to={ `/employee/detail/${ token?.employee_id }` } onClick={ onUserMenuClose() }>
              <ListItemIcon sx={ { minWidth: '24px' } }>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Your detail" sx={ { whiteSpace: 'nowrap' } } />
            </MenuItem>
            <MenuItem component={ RouterLink } to="/profile" onClick={ onUserMenuClose() }>
              <ListItemIcon sx={ { minWidth: '24px' } }>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Account setting" sx={ { whiteSpace: 'nowrap' } } />
            </MenuItem>
            <MenuItem onClick={ onUserMenuClose(signOut) }>
              <ListItemIcon sx={ { minWidth: '24px' } }>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" sx={ { whiteSpace: 'nowrap' } } />
            </MenuItem>
          </Menu>
        </Toolbar>
        <Divider />
      </AppBar>
      <Drawer anchor="left" classes={ { paper: classes.drawerPaper } } className={ classes.drawer } variant="permanent">
        <Box className={ classes.drawerHeader }>
          <img alt="Logo" src={ logo_black } style={ { width: `${ leftImageWidth }px` } } />
          <Typography ref={ refLeftTitle } lineHeight={ 1 } variant="overline">Master Employee Database</Typography>
        </Box>
        <Box>
          <ShowIf test={ signInStatus.status === SignInStatus.SIGNED_IN }>
            {
              MenuListItems.map((items, index) => (
                <Fragment key={ index }>
                  <ShowIf test={ index > 0 }>
                    <Divider />
                  </ShowIf>
                  <List disablePadding={ true }>
                    <MenuListItemsComponent childrenCount={ 0 }
                                            index={ index }
                                            items={ items }
                                            keyPrefix=""
                                            setUserNameAnchorEl={ setUserNameAnchorEl }
                                            token={ token }
                                            userAccount={ userAccount }
                    />
                  </List>
                </Fragment>
              ))
            }
          </ShowIf>
        </Box>
        <Box flex={ 1 } />
        <Divider />
        <Box padding="8px 16px">
          <Typography color="textSecondary" component="div" variant="caption">
            Build number: FE&nbsp;
            { Configuration.build_number }
            &nbsp;/&nbsp;BE&nbsp;
            { backendBuildNumber }
          </Typography>
        </Box>
      </Drawer>

      <HelpDialog employeeCompanyId={ employee?.currentEmployee?.employeeCurrentPosition?.companyId } setShowHelpDialog={ setShowHelpDialog } showHelpDialog={ showHelpDialog } />
    </Box>
  );
};

const Header = () => {
  const [signInStatus] = useGlobalState('signInStatus');

  if (signInStatus.status === SignInStatus.SIGNED_IN) {
    return <HeaderComponent />;
  } else {
    return <></>;
  }
};

export default Header;
