import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { Location } from '@remix-run/router';
import queryString from 'query-string';

import { Configuration } from '../config';
import { parseUrlToLocationDescriptor, stringifyLocationDescriptor } from '../services/urlService';

interface RouterPreviousLocationContextProps {
  previous: Location | undefined;
  current: Location | undefined;
}

const RouterPreviousLocationContext = createContext<RouterPreviousLocationContextProps>({ previous: undefined, current: undefined });

export const RouterPreviousLocationProvider = (props: { children: ReactNode }) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const [route, setRoute] = useState<RouterPreviousLocationContextProps>({
    previous: location,
    current: location.pathname === '/signIn' ? parseUrlToLocationDescriptor(queryString.parse(location.search)?.['next'] as any ?? '/dashboard') : undefined,
  });

  useEffect(() => {
    setRoute(prev => {
      if (stringifyLocationDescriptor(location) === stringifyLocationDescriptor(prev.current) || location.key === prev.current?.key) {
        return prev;
      }

      if (navigationType !== 'PUSH') {
        return prev;
      }

      const fromUrl = `${ Configuration.frontendUrlBase }${ stringifyLocationDescriptor(prev?.current) }`;
      const toUrl = `${ Configuration.frontendUrlBase }${ stringifyLocationDescriptor(location) }`;

      console.log(`[RouterPreviousLocationProvider] ${ fromUrl } -> ${ toUrl }`);

      return { previous: prev?.current, current: location };
    });
  }, [location, navigationType]);

  return (
    <RouterPreviousLocationContext.Provider value={ route }>
      { props.children }
    </RouterPreviousLocationContext.Provider>
  );
};

const useRouterPreviousLocation = () => useContext(RouterPreviousLocationContext);

export default useRouterPreviousLocation;
