import { get, isArray, isEmpty, isEqual, isNil, isNumber, isObject, isString, trim as lodashTrim, transform } from 'lodash';

export type ChangedObject = Record<string, unknown>;

export type Nullable<T> = T | null;

export type NullOrUndefinedOr<T> = T | null | undefined;

/**
 * https://davidwells.io/snippets/get-difference-between-two-objects-javascript
 *
 * @param orgObj
 * @param newObj
 */
export const difference = <T = ChangedObject>(orgObj: T, newObj: T): Partial<T> => {
  const changes = (newObj: any, origObj: any) => {
    let arrayIndexCounter = 0;

    return transform(newObj, (result: any, value: any, key: string | number) => {
      if (!isEqual(value, get(origObj, key))) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;

        result[resultKey] = isObject(value) && isObject(get(origObj, key)) ? changes(value, get(origObj, key)) : value;
      }
    });
  };

  return changes(newObj, orgObj);
};

export interface Difference<T = ChangedObject> {
  key: any;
  value: Partial<T>;
}

export interface Differences<T = ChangedObject> {
  onlyLeft: Array<Difference<T>>;
  both: Array<Difference<T>>;
  onlyRight: Array<Difference<T>>;
}

interface ObjectArrayMapValue {
  orgObjIndex: number | null;
  newObjIndex: number | null;
}

export const differences = <T = ChangedObject>(primaryKey: keyof T, orgObjArray: ReadonlyArray<T>, newObjArray: ReadonlyArray<T>): Differences<T> => {
  const maps: Array<ObjectArrayMapValue> = [];
  const excludedNewObjIndex: Array<number> = [];

  for (let i = 0; i < orgObjArray.length; i++) {
    const mapObj: ObjectArrayMapValue = { orgObjIndex: i, newObjIndex: null };
    const newObjIndex = newObjArray.findIndex(n => orgObjArray[i][primaryKey] === n[primaryKey]);

    if (newObjIndex >= 0) {
      mapObj.newObjIndex = newObjIndex;
      excludedNewObjIndex.push(newObjIndex);
    }
    maps.push(mapObj);
  }

  for (let i = 0; i < newObjArray.length; i++) {
    if (!excludedNewObjIndex.includes(i)) {
      maps.push({ orgObjIndex: null, newObjIndex: i });
    }
  }

  const ret: Differences<T> = {
    onlyLeft: [],
    both: [],
    onlyRight: [],
  };

  for (const both of maps) {
    if (isNumber(both.orgObjIndex) && !isNumber(both.newObjIndex)) {
      ret.onlyLeft.push({ key: orgObjArray[both.orgObjIndex][primaryKey], value: orgObjArray[both.orgObjIndex] });
    } else if (!isNumber(both.orgObjIndex) && isNumber(both.newObjIndex)) {
      ret.onlyRight.push({ key: newObjArray[both.newObjIndex][primaryKey], value: newObjArray[both.newObjIndex] });
    } else if (isNumber(both.orgObjIndex) && isNumber(both.newObjIndex)) {
      const diff = difference(orgObjArray[both.orgObjIndex], newObjArray[both.newObjIndex]);

      if (!isEmpty(diff)) {
        ret.both.push({ key: orgObjArray[both.orgObjIndex][primaryKey], value: diff });
      }
    }
  }

  return ret;
};

export const hasDifferences = <T = ChangedObject>(diffs?: Differences<T>) => {
  if (!diffs) {
    return false;
  }

  return !isEmpty(diffs.onlyLeft) || !isEmpty(diffs.both) || !isEmpty(diffs.onlyRight);
};

const isEmptyValueMap = (trim: boolean, v: any) => {
  if (trim && isString(v)) {
    return (v ?? '').trim();
  } else if (isNumber(v)) {
    return v.toString();
  } else {
    return v;
  }
};

export const isEmptyAll = (trim: boolean, ...values: Array<any>) => {
  if (isEmpty(values)) {
    return true;
  }

  return values.map(v => isEmptyValueMap(trim, v)).every(v => isEmpty(v));
};

export const isEmptySome = (trim: boolean, ...values: Array<any>) => {
  if (isEmpty(values)) {
    return true;
  }

  return values.map(v => isEmptyValueMap(trim, v)).some(v => isEmpty(v));
};

export const isNotEmptyAll = (trim: boolean, ...values: Array<any>) => {
  return !isEmptySome(trim, ...values);
};

export const isNotEmptySome = (trim: boolean, ...values: Array<any>) => {
  if (isEmpty(values)) {
    return false;
  }

  return values.map(v => isEmptyValueMap(trim, v)).some(v => !isEmpty(v));
};

export const coalesce = (...values: Array<any>) => {
  for (const value of values) {
    if (!isNil(value)) {
      return value;
    }
  }

  return undefined;
};

export const coalesceNotEmpty = (trim: boolean, ...values: Array<any>) => {
  for (const value of values) {
    const _value = trim ? lodashTrim(value) : value;

    if (!isEmpty(_value)) {
      return _value;
    }
  }

  return undefined;
};

export const toKeysFromKeyOf = <T>(...names: Array<keyof T>): Array<keyof T> => names;
