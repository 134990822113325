import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { chain, head, toNumber } from 'lodash';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useTokenCookie from '../../hooks/useTokenCookie';
import { NullOrUndefinedOr } from '../../services/objectService';
import { getEmployeeNameWithNickname } from '../../services/stringService';
import { SecondaryButton } from '../button';
import LinkInNewWindow from '../link/linkInNewWindow';
import Selector from '../selector';
import { getSelectorMenuItemsCompany } from '../selector/menuItemUtil';

import { useHeader_LocalHrQuery } from './__generated__';

export const HelpDialog: FC<{ employeeCompanyId: NullOrUndefinedOr<string>; setShowHelpDialog: Dispatch<SetStateAction<boolean>>; showHelpDialog: boolean; }> = props => {
  const { rawToken } = useTokenCookie();

  const [selectedLocalHrCompany, setSelectedLocalHrCompany] = useState<string>('');

  const { data: localHrs } = useHeader_LocalHrQuery({
    skip: !props.showHelpDialog,
  });

  useEffect(() => {
    if (!localHrs?.companyLocalHrs) {
      return;
    }

    if (props.showHelpDialog) {
      setSelectedLocalHrCompany(localHrs.companyLocalHrs.nodes.find(n => n.company?.companyId === props.employeeCompanyId)?.company?.companyId ?? '');
    }
  }, [localHrs, props.employeeCompanyId, props.showHelpDialog]);

  return (
    <Dialog open={ props.showHelpDialog }>
      <DialogTitle>Help</DialogTitle>
      <DialogContent>
        <Box marginBottom="1.4em">
          <Typography gutterBottom={ true } variant="h5">For general employee</Typography>
          <Box marginBottom="1.4em">
            <Typography variant="h6">Performance review</Typography>
            <Box>
              Refer the portal site:&nbsp;
              <LinkInNewWindow to="https://sites.google.com/anymindgroup.com/performancereview/home" withReactRouter={ false }>AnyMind Performance Review</LinkInNewWindow>
            </Box>
          </Box>
          <Box marginBottom="1.4em">
            <Typography variant="h6">Any other questions?</Typography>
            <Box>If you have any other questions, ask your local HR.</Box>
            {
              !localHrs?.companyLocalHrs
                ? ''
                : (
                  <Box margin="8px 0">
                    <Selector menuItems={
                                (
                                  getSelectorMenuItemsCompany(
                                    chain(localHrs.companyLocalHrs.nodes)
                                      .uniqBy('company.companyId')
                                      .orderBy(['company.countryByCountryCodeAlpha2.displayOrder', 'company.fullName'])
                                      .map(it => it.company)
                                  )
                                )
                              }
                              sx={ { marginBottom: '8px' } }
                              value={ selectedLocalHrCompany }
                              onChange={ setSelectedLocalHrCompany }
                    />
                    {
                      chain(localHrs.companyLocalHrs.nodes)
                        .filter(n => n.company?.companyId === selectedLocalHrCompany)
                        .orderBy([n => toNumber(n.employee?.employeeCurrentPosition?.employeeLevel?.employeeLevelOrder), 'employee.fullName'], ['desc', 'asc'])
                        .map(n => {
                          const googleProfilePictureUrl = head(n.employee?.relationEmployeeUserAccounts?.nodes)?.userAccount?.googleProfilePictureUrl ?? undefined;

                          return (
                            <Grid key={ n.nodeId } alignItems="center" container={ true } spacing={ 1 }>
                              <Grid item={ true }>
                                <Avatar alt={ n.employee?.fullName } src={ `${ googleProfilePictureUrl }?authorization=${ rawToken }` } sx={ { width: '32px', height: '32px' } } />
                              </Grid>
                              <Grid item={ true }>
                                <Typography letterSpacing="1px" variant="body1">
                                  { getEmployeeNameWithNickname(n.employee) }
                                </Typography>
                                <Typography variant="body2">
                                  { n.employee?.companyEmail }
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })
                        .value()
                    }
                  </Box>
                )
            }
          </Box>
        </Box>

        <Typography variant="h5">For HR</Typography>
        <Typography gutterBottom={ true } variant="body1">
          <LinkInNewWindow to="https://anymindgroup.slack.com/archives/C061MLDT7FY" withReactRouter={ false }>Slack</LinkInNewWindow>
        </Typography>
        <Typography variant="body1">
          <LinkInNewWindow to="https://sites.google.com/anymindgroup.com/med-faq-for-hr/home" withReactRouter={ false }>Frequently asked questions</LinkInNewWindow>
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={ () => props.setShowHelpDialog(false) }>Close</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};
