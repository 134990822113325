import { DispatchWithoutAction, RefObject, useRef } from 'react';

const useFocus = <TRef extends HTMLElement = HTMLInputElement>(): [RefObject<TRef>, DispatchWithoutAction] => {
  const htmlElRef = useRef<TRef>(null);
  const focusFunc = () => {
    try {
      htmlElRef.current && htmlElRef.current.focus();
    } catch (e) {
      // do nothing
    }
  };

  return [htmlElRef, focusFunc];
};

export default useFocus;
