import { GraphQLError } from 'graphql';
import { isEmpty, trim } from 'lodash';

import { ServerError, ServerParseError } from '@apollo/client';

import { Configuration } from '../config';

import { JwtBody } from './jwtService';

const WebhookURL = 'https://hooks.slack.com/services/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';

type Errors =
  | {
      type: 'GraphQLError';
      error: GraphQLError;
    }
  | {
      type: 'NetworkError';
      error: ServerError | ServerParseError | Error;
    }
  | {
      type: 'Error';
      error: Error;
    };

export const sendClientErrorToSlack = async (args: Errors, token: JwtBody | undefined, rawToken: string) => {
  if (args.error.message === 'jwt expired' || args.error.message.startsWith('Socket closed with event') || window.location.toString().startsWith('http://localhost:')) {
    return;
  }

  // noinspection SpellCheckingInspection
  const bodyBlockCommon = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'User',
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Display name*\n${ token?.display_name }`,
        },
        {
          type: 'mrkdwn',
          text: `*User account ID*\n${ token?.user_account_id }`,
        },
        {
          type: 'mrkdwn',
          text: `*Role*\n${ token?.role }`,
        },
        {
          type: 'mrkdwn',
          text: `*Token*\n\`${ rawToken }\``,
        },
      ],
    },
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'Browser',
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*URL*\n<${ window.location.toString() }>`,
        },
        {
          type: 'mrkdwn',
          text: `*Build number (FE)*\n${ Configuration.build_number }`,
        },
      ],
    },
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'Error (Common)',
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Message*\n\`${ args.error.message.substr(0, 3000 - 12) }\``,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Stacktrace*\n\`\`\`${ args.error.stack
          ?.toString()
          .replace(/ {4}at /g, '\n    at ')
          .substr(0, 3000 - 20) }\`\`\``,
      },
    },
  ];

  let bodyError: any;

  try {
    if (args.type === 'GraphQLError') {
      if (args.error.message === 'Subscription denied') {
        return;
      }

      // noinspection SpellCheckingInspection
      bodyError = {
        text: args.error.message,
        mrkdwn: false,
        blocks: [
          ...bodyBlockCommon,
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: args.error.name ?? 'GraphQLError',
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*OriginalError*\n${ !args.error.originalError ? '(None)' : `${ args.error.originalError.name }:${ args.error.originalError.message.substr(0, 2000) }` }`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Extensions*\n${ !args.error.extensions ? '(None)' : `\`\`\`${ JSON.stringify(args.error.extensions, null, 2).substr(0, 3000 - 19) }\`\`\`` }`,
            },
          },
          {
            type: 'section',
            fields: [
              // {
              //   type: 'mrkdwn',
              //   text: `*Positions*: ${ args.error.positions }`,
              // },
              // {
              //   type: 'mrkdwn',
              //   text: `*Source*\n${ !args.error.source ? '(None)' : `Name: ${ args.error.source.name }\nLocation: ${ args.error.source.locationOffset.line }:${ args.error.source.locationOffset.column }\nBody: ${ args.error.source?.body ?? '(None)' }` }`,
              // },
              ...(args.error.locations ?? []).map((l, i) => ({
                type: 'mrkdwn',
                text: `*Locations(${ i })*: ${ l.line }:${ l.column }`,
              })),
              ...(args.error.nodes ?? []).map((n, i) => ({
                type: 'mrkdwn',
                text: `*Nodes(${ i })*\n${ n.kind } / ${ n.loc?.toJSON() }`,
              })),
              ...(args.error.path ?? []).map((p, i) => ({
                type: 'mrkdwn',
                text: `*Path(${ i })*: ${ p }`,
              })),
            ],
          },
        ],
      };
    } else if (args.type === 'NetworkError' && 'statusCode' in args.error) {
      // noinspection SpellCheckingInspection
      bodyError = {
        text: args.error.message,
        mrkdwn: false,
        blocks: [
          ...bodyBlockCommon,
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: args.error.name ?? 'ServerError or ServerParseError',
            },
          },
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Status Code*\n${ args.error.statusCode }`,
              },
              {
                type: 'mrkdwn',
                text: `*Response*\n${ args.error.response }`,
              },
              {
                type: 'mrkdwn',
                text: `*Body text*\n${ 'bodyText' in args.error ? args.error.bodyText : '(None)' }`,
              },
              {
                type: 'mrkdwn',
                text: `*Result*\n${ 'result' in args.error ? JSON.stringify(args.error.result) : '(None)' }`,
              },
            ],
          },
        ],
      };
    } else {
      // noinspection SpellCheckingInspection
      bodyError = {
        text: args.error.message,
        mrkdwn: false,
        blocks: [...bodyBlockCommon],
      };
    }

    if (!bodyError || isEmpty(trim(bodyError))) {
      return;
    }

    await fetch(WebhookURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: JSON.stringify(bodyError),
    });
  } catch (e) {
    // Failed to send to slack
    console.error(e);
  }
};
