import React, { FC } from 'react';

import { isArray, isNil } from 'lodash';

import { SxProps } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

interface Props {
  progressProps?: LinearProgressProps;
  omitWrapper?: boolean;
  wrapperProps?: BoxProps;
}

const sxProgressWrapper: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flex: 1,
  padding: '8px 20%',
  marginTop: '48px',

  '> .progress': {
    width: '100%',
  },
};

const LoadingBar: FC<Props> = ({ omitWrapper, progressProps, wrapperProps }) => {
  const component = (
    <Box className="progress">
      <LinearProgress { ...progressProps } />
    </Box>
  );

  if (omitWrapper) {
    return component;
  }

  return (
    <Box { ...wrapperProps } sx={ [ ...(isNil(wrapperProps) ? [] : isArray(wrapperProps.sx) ? wrapperProps.sx : [wrapperProps.sx]), sxProgressWrapper ] }>
      { component }
    </Box>
  );
};

export default LoadingBar;
