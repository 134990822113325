import React, { ReactNode, useCallback, useMemo } from 'react';

import { isFunction, isNil } from 'lodash';

import { isEmptySome, NullOrUndefinedOr } from '../../services/objectService';

interface Props<V> {
  children: ((value: NonNullable<V>) => ReactNode) | { ifTrue: (value: NonNullable<V>) => ReactNode; ifFalse: () => ReactNode; };
  test?: boolean | ((value: NonNullable<V>) => boolean);
  value: NullOrUndefinedOr<V>;
}

function ShowIfNotEmpty<V>(props: Props<V>) {
  const { children, test, value } = useMemo(() => props, [props]);

  const testResult = useCallback((value: NullOrUndefinedOr<V>): value is NonNullable<V> => {
    if (isNil(value) || isEmptySome(true, value)) {
      return false;
    } else if (isNil(test)) {
      return true;
    } else if (!isFunction(test)) {
      return test;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return test(value!);
    }
  }, [test]);

  if (testResult(value)) {
    return isFunction(children) ? <>{ children(value) }</> : <>{ children.ifTrue(value) }</>;
  }

  return isFunction(children) ? <></> : <>{ children.ifFalse() }</>;
}

export default ShowIfNotEmpty;
